<template>
  <div class="register" :style="backgroundDiv">
    <blockquote>
      <a-col span="24" class="loginTitle">
        <img src="../assets/logo-h5.png" />
        <span>翻红馆</span>
      </a-col>
      <a-form
        id="components-form-demo-normal-login"
        :form="form"
        class="login-form"
        @submit="handleSubmit"
      >
        <a-form-item>
          <a-input
            class="first-child"
            autocomplete="off"
            v-decorator="[
              'userName',
              {
                rules: [{ required: false, message: '请输入用户名!' }],
              },
            ]"
            placeholder="输入手机号"
            :maxLength="phoneLength"
          >
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-input
            class="last-child"
            autocomplete="off"
            v-decorator="[
              'password',
              {
                rules: [{ required: false, message: '请输入密码!' }],
              },
            ]"
            placeholder="输入验证码"
            :maxLength="codelength"
          >
          </a-input>
          <span v-if="time > 0">{{ time }}s后再次获取</span>
          <span @click="getCode" v-if="time == 0">获取验证码</span>
        </a-form-item>
        <a-form-item class="button">
          <a-button type="primary" html-type="submit" class="login-form-button">
            马上登录
          </a-button>
        </a-form-item>
        <router-link tag="a" to="/register" class="gotoLogin"
          >还没有账号，马上注册</router-link
        >
      </a-form>
    </blockquote>
    <!-- <div class="tip">
      <span>有疑问，请咨询客服</span>
      <img src="../assets/arrow-h5.png" />
    </div> -->
    <div class="showToast" v-if="showToast">
      <div class="showToast_con">{{ showToast }}</div>
    </div>

    <div class="success_tip" v-if="success_tip" @click="closeTip">
      <img src="../assets/register-tip.png" />
    </div>
  </div>
</template>

<script>
// import 'lib-flexible'

import { resetRem } from "@/assets/rem.js";
export default {
  data() {
    return {
      spinning: true,
      success_tip: false,
      form: this.$form.createForm(this, { name: "normal_login" }),
      time: 0,
      showToast: "",
      phoneLength: 11,
      codelength: 6,
      userName: '',
      inviteCode: this.$route.query.inviteCode,
      imgUrl: '',
      phone: "",
      backgroundDiv: {
        backgroundImage: "url(" + require("../assets/bg-h5.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      },
    };
  },
  created() {
    resetRem();
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "normal_login" });
  },
  activated() {
    this.form.setFieldsValue({
      userName: "",
      password: "",
    });
  },
  mounted(){
    // this.meInvite() 
  },
  methods: {
    getCode() {
      var that = this;
      that.form.validateFields((err, values) => {
        if (!values.userName) {
          that.showToast = "请填写手机号获取";
          setTimeout(function () {
            that.showToast = "";
          }, 2000);
        } else {
          let reg = /^1\d{10}$/;
          if (reg.test(values.userName)) {
            that.time = 60;
            var data = { mobile: values.userName };
            that.axios.post("/app/sendSms/login", data).then((res) => {
              that.phone = values.userName;
              if (res.data.code == 200) {
                that.showToast = "验证码发送成功";
                setTimeout(function () {
                  that.showToast = "";
                }, 2000);
              } else if (res.data.code == 5003) {
                that.showToast = res.data.msg;
                setTimeout(function () {
                  that.showToast = "";
                }, 2000);
                that.register = true;
              } else {
                that.showToast = res.data.msg;
                setTimeout(function () {
                  that.showToast = "";
                }, 2000);
                that.register = false;
                that.form.setFieldsValue({
                  password: "",
                });
              }
            });
            var timer = setInterval(function () {
              that.time = that.time - 1;
              if (that.time <= 0) {
                that.time = 0;
                clearInterval(timer);
              }
            }, 1000);
          } else {
            that.showToast = "请填写正确的手机号";
            setTimeout(function () {
              that.showToast = "";
            }, 2000);
          }
        }
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      var that = this;
      that.form.validateFields((err, values) => {
        if (!values.userName || !values.password) {
          that.showToast = "请输入手机号验证码";
          setTimeout(function () {
            that.showToast = "";
          }, 2000);
          return false;
        } else {
          if (values.userName != that.phone) {
            that.showToast = "请填写正确的手机号";
            setTimeout(function () {
              that.showToast = "";
            }, 2000);
            return false;
          }
          let regCode = /^\d{6}$/;
          if (!regCode.test(values.password)) {
            that.showToast = "请填写正确的验证码";
            setTimeout(function () {
              that.showToast = "";
            }, 2000);
            return false;
          }
          var data = {
            mobile: values.userName,
            code: values.password,
            invite_code: that.inviteCode,
          };
          that.axios.post("/app/login", data).then((res) => {
            if (res.data.code == 200) {
              that.showToast = "登录成功";
              setTimeout(function () {
                that.showToast = "";
              }, 2000);
              window.localStorage.setItem(
                "token",
                res.data.data.token
              );
              that.$router.push({
                path: "/centerApp",
            });
            } else {
              that.showToast = res.data.msg;
              setTimeout(function () {
                that.showToast = "";
              }, 2000);
              that.form.setFieldsValue({
                password: "",
              });
            }
          });
        }
        // if (!err) {
        //   }
      });
    },
    closeTip() {
      this.success_tip = false;
    },
  },
};
</script>
<style scoped>
.register {
  width: 10rem;
  margin: 0 auto;
  height: 100vh;
  background: url("../assets/bg-h5.png") no-repeat 50% 50%;
  background-size: cover;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.register .ant-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.register .loginTitle {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: -2.2rem;
  margin-bottom:0.64rem;
}
.register .loginTitle img {
  width: 2.56rem;
  margin-bottom: 0.21333rem;
}
.register .loginTitle span {
  font-family: PingFang-SC-Heavy;
  font-size: 0.64rem;
  line-height: 0.88rem;
  color: #ddb383;
  letter-spacing: 0;
  text-align: center;
}
.register .registerTip {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin: 0.64rem 0;
}
.register .registerTip img {
  width: 0.4267rem;
  height: 0.4267rem;
  margin-right: 0.16rem;
  border-radius: 50%;
}
.register .registerTip span {
  font-family: PingFangSC-Regular;
  font-size: 0.32rem;
  color: #ffffff;
  letter-spacing: 0;
}
.register .registerTip span span {
  color: #ddb383;
}
.register .ant-form-item {
  margin-bottom: 0;
  height: 1.333rem;
  width: 7.6267rem;
  /* border: 0.0267rem solid #FFFFFF; */
}
.register .ant-form-item:last-child {
  height: auto;
  margin-top: 0.64rem;
}
.register .ant-form-item input {
  padding: 0.4267rem;
  height: 1.333rem;
  width: 7.6267rem;
  border-radius: 0;
  border: 0.0267rem solid #3b3b3b;
  background: #272727;
  box-sizing: border-box;
  font-family: PingFangSC-Regular;
  font-size: 0.3733rem;
  color: #fff;
  background: transparent !important;
  position: relative;
}
.register .ant-form-item span {
  display: block;
  height: 1.333rem;
  line-height: 1.333rem;
}
.register .ant-form-item span > span {
  position: absolute;
  top: 0;
  right: 0;
  height: 1.333rem;
  line-height: 1.333rem;
  padding-right: 0.4267rem;
  font-family: PingFangSC-Regular;
  font-size: 0.3733rem;
  color: #ddb383;
  letter-spacing: 0;
}
.register .ant-form-item input:focus,
.register .ant-input:hover,
.register .ant-form-item input:active {
  border: 0.0267rem solid #3b3b3b;
  box-shadow: none;
}
.register .ant-form-item input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px transparent;
}
.register .ant-form-item input.first-child {
  border-top-right-radius: 0.1067rem;
  border-top-left-radius: 0.1067rem;
  border-bottom: 0;
}
/deep/.ant-form-item-children {
  display: block;
  height: 100%;
}
.register .ant-form-item input.last-child {
  border-bottom-right-radius: 0.1067rem;
  border-bottom-left-radius: 0.1067rem;
}
.register .ant-form-item input::-webkit-input-placeholder {
  opacity: 0.3;
  font-family: PingFangSC-Regular;
  font-size: 0.3733rem;
  color: #ffffff;
  font-weight: normal;
}
.register .ant-btn-primary {
  display: block;
  width: 7.6267rem;
  height: 1.3333rem;
  background-image: linear-gradient(-45deg, #ddb383 0%, #b17b55 100%);
  border-radius: 0.1067rem;
  border: 0;
  font-family: PingFangSC-Regular;
  font-size: 0.4267rem;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  margin: 0 auto;
}
.register .ant-form-item.button {
  margin-top: 0.64rem;
  margin-bottom: 0.64rem;
}
.register .ant-btn-primary:hover,
.register .ant-btn-primary:focus,
.register .ant-btn-primary:active,
.register .ant-btn-primary.active {
  background-image: linear-gradient(-45deg, #ddb383 0%, #b17b55 100%);
}
/* .register .tip {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  font-family: PingFangSC-Regular;
  font-size: 0.3733rem;
  color: #ddb383;
  letter-spacing: 0;
  text-align: center;
  margin-top: 0.64rem;
}
.register .tip img {
  width: 0.5333rem;
} */
.register .showToast {
  width: 10rem;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
}
.register .showToast_con {
  height: 1.333rem;
  line-height: 1.333rem;
  background: #4c4c4c;
  border-radius: 0.667rem;
  padding: 0 1.28rem;
  font-family: PingFang-SC-Regular;
  font-size: 0.4267rem;
  color: #ffffff;
  letter-spacing: 0;
}
.register .successTitle img {
  width: 1.7067rem;
  margin-bottom: 0.42667rem;
}
.register .successTitle {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: PingFang-SC-Heavy;
  font-size: 0.64rem;
  line-height: 0.88rem;
  color: #ddb383;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 0.8533rem;
  margin-top: -2.2rem;
}
.register blockquote {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.success_tip {
  position: fixed;
  top: 0;
  left: 0;
  width: 10rem;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}
.success_tip img {
  width: 10rem;
}
.gotoLogin {
  display: block;
  width: 7.6267rem;
  height: 1.3333rem;
  line-height: 1.3333rem;
  background: rgba(221, 179, 131, 0.1);
  border-radius: 0.1067rem;
  border: 0;
  font-family: PingFangSC-Regular;
  font-size: 0.4267rem;
  letter-spacing: 0;
  text-align: center;
  margin: 0 auto;
  color: #ddb383;
  margin-top: -0.32rem;
}
</style>