var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register",style:(_vm.backgroundDiv)},[_c('blockquote',[_c('a-col',{staticClass:"loginTitle",attrs:{"span":"24"}},[_c('img',{attrs:{"src":require("../assets/logo-h5.png")}}),_c('span',[_vm._v("翻红馆")])]),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'userName',
            {
              rules: [{ required: false, message: '请输入用户名!' }],
            } ]),expression:"[\n            'userName',\n            {\n              rules: [{ required: false, message: '请输入用户名!' }],\n            },\n          ]"}],staticClass:"first-child",attrs:{"autocomplete":"off","placeholder":"输入手机号","maxLength":_vm.phoneLength}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [{ required: false, message: '请输入密码!' }],
            } ]),expression:"[\n            'password',\n            {\n              rules: [{ required: false, message: '请输入密码!' }],\n            },\n          ]"}],staticClass:"last-child",attrs:{"autocomplete":"off","placeholder":"输入验证码","maxLength":_vm.codelength}}),(_vm.time > 0)?_c('span',[_vm._v(_vm._s(_vm.time)+"s后再次获取")]):_vm._e(),(_vm.time == 0)?_c('span',{on:{"click":_vm.getCode}},[_vm._v("获取验证码")]):_vm._e()],1),_c('a-form-item',{staticClass:"button"},[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 马上登录 ")])],1),_c('router-link',{staticClass:"gotoLogin",attrs:{"tag":"a","to":"/register"}},[_vm._v("还没有账号，马上注册")])],1)],1),(_vm.showToast)?_c('div',{staticClass:"showToast"},[_c('div',{staticClass:"showToast_con"},[_vm._v(_vm._s(_vm.showToast))])]):_vm._e(),(_vm.success_tip)?_c('div',{staticClass:"success_tip",on:{"click":_vm.closeTip}},[_c('img',{attrs:{"src":require("../assets/register-tip.png")}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }